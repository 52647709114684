import React, { useEffect, useState } from "react";
import axios from "axios";
import pdf from "../../../assets/img/pdf.png";
import { API } from "../../../config.json";
import { Col, Container, Row } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import { authenticate } from "../../Auth/CheckUser";
import { FaRegUserCircle } from "react-icons/fa";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import ReviewQuality from "./ReviewQuality";

function Works(props) {
  const [work, setWork] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [userInfo, setuserInfo] = useState("");

  const [err, setError] = useState("");

  const history = useHistory();

  const [area, setArea] = useState([]);
  const [avgMark, setAvgMark] = useState();
  const [values, setValues] = useState({
    assessment: "",
    recomend: Boolean,
    review: "",
  });

  const [showCommentInfo, setShowCommentInfo] = useState(true);

  const title = props.match.params.title;
  const guid = props.match.params.guid;
  const url = `${API}routes/get_work_reviews.php?w=${guid}`;
  const isAuth = authenticate();
  const token = localStorage.getItem("Access_token");
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  };

  function displayCommentInfo(){
    if(showCommentInfo){
      Swal.fire({
        html: "<p style=\"text-align: justify;\">Uneti recenziju koja će sadržati minimum 40 reči i informaciju o oceni orginalnosti, metodologiji, rezultatima i prezentaciji rada.</p>" +  
              "<br>" +
              "<p style=\"text-align: justify;\">Recenzija rada treba da se odnosi na rezultat koji je prikazan u radu, relevantnost, doprinos rada i osvrt na navedenu literaturu. Imajte na umu za koga pišete recenziju. Recenzija je namenjena drugim studetima, nastavnicima i budućim poslodavcima. Ne treba prepričavati rad već dati sažet prikaz. Recenzija treba da bude iskrena i konstruktivna.</p>",
        icon: "info",
        showCancelButton: false,
      }).then(setShowCommentInfo(false))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let { assessment, review, recomend } = values;

    // if( !assessment ){
    //   Swal.fire({
    //     title: "Please rate this work with few stars.",
    //     icon: "error",
    //   })
    //   return;
    // }

    let completeReview = { assessment, review, recomend };
    
    axios
      .post(
        `${API}routes/set_work_reviews.php?w=${guid}`,
        completeReview,
        options
      )
      .then((res) => {
        //need to check success or error msg
        res.data.status !== 200
          ? setError(res.data.errors)
          : Swal.fire({
              title: "Success!",
              text: `${res.data.success_msg}`,
              icon: "success",
            }).then(function () {
              // window.location.href = "/dashboard";
              history.goBack();
            });

        // res.status === 200 ? window.location.reload() : console.log("error");
      });
  };

  const handleBack = () => {
    history.goBack();
  };

  let err_msg = "";
  if (err) {
    err_msg = (<div className="error-msg">{err}</div>);
  }

  const onChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };
  const ratingChanged = (assessment) => {
    setValues({ ...values, assessment: assessment });
  };
  useEffect(() => {
    async function getReviewWork() {
      axios
        .get(url, options)
        .then((res) => {
          setWork(res.data.work_info);
          setArea(res.data.area);
          setReviews(res.data.review);
          setAvgMark(res.data.avg_mark);
        })
        .catch((err) => {
          console.error(`Error: ${err}`);
        });
    }
    const getUserInfo = async () => {
      await axios.get(`${API}routes/user_info.php`, options).then((res) => {
        setuserInfo(res.data);
        document.cookie = "isAdmin=" + res.data.isAdmin + ";";
      });
    };
    getUserInfo();
    getReviewWork();
  }, [url]);
  // const ColoredLine = ({ color }) => (
  //   <hr
  //     style={{
  //       color: color,
  //       backgroundColor: color,
  //       height: 5,
  //     }}
  //   />
  // );
  // console.log(work);
  // return(
  //   <></>
  // );
  return (
    <Container className="newWork">
      <Row xl={2} lg={2} md={1} sm={1} xs={1} className="newRowCard">
        <Col xl={2} lg={3} md={5} sm={7} xs={8} style={{ marginRight: "3rem" }}>
          <a
            href={`//open-rev.com/backend-blockchain-live/download.php?file=${work.pdf}&title=${work.title}`}
            rel="noreferrer"
          >
            <img
              src="https://open-rev.com/static/media/pdf.5314bcc1.png"
              alt="PDF icon"
              width="220"
              height="220"
            />
          </a>

          <b>
            Added: <small> {work.publish_date}</small>
          </b>
          <p>
            <b>
              Category:<small> {area}</small>
            </b>
          </p>
          {avgMark && avgMark !== undefined ? (
            <div className="stars">
              Avg Mark: {avgMark.toFixed(2)}
              <ReactStars
                count={5}
                edit={false}
                size={25}
                isHalf={true}
                value={avgMark}
                activeColor="#ffd700"
              />
            </div>
          ) : (
            ""
          )}
        </Col>
        <Col xl={8} lg={8}>
        
        <h3>{work.title}</h3>

        <h5><strong>Author:</strong> {work.author}</h5>

        <p><strong>Abstract:</strong> {work.abstract}</p>

        <p><strong>Keywords:</strong> {work.keywords}</p>

        </Col>
      </Row>
      {isAuth && work.guid !== userInfo.GUID ? (
        <div className="auth-wrapper-new">
          <div className="auth-inner-new">
            <form
              onSubmit={handleSubmit}
              //style={{ margin: "-1rem", padding: "unset" }}
            >
              <div style={{ float: "right" }}>
                <AiIcons.AiOutlineCloseCircle onClick={handleBack} size={25} />
              </div>
              <h3>Give review on work</h3>
              <div className="form-group-new">
                <label>Recomendation? *</label> &nbsp; Yes{" "}
                <input
                  name="recomend"
                  type="radio"
                  value={"Yes"}
                  onChange={onChange("recomend")}
                />{" "}
                &nbsp; No{" "}
                <input
                  name="recomend"
                  type="radio"
                  value={"No"}
                  onChange={onChange("recomend")}
                />
                <div className="stars">
                  <ReactStars
                    name="assessment"
                    count={5}
                    onChange={ratingChanged}
                    size={25}
                    activeColor="#ffd700"
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Comment: *</label>
                <textarea
                  value={values.review}
                  type="text"
                  className="form-control"
                  placeholder="Enter comment"
                  onChange={onChange("review")}
                  onFocus={displayCommentInfo}
                  required
                />
              </div>

              {err_msg}

              <button
                type="submit"
                className="btn  btn-sm btn-primary float-right mr-5"
              >
                Submit
              </button>
            </form>
            
          </div>
        </div>
      ) : null}

      <Row xl={1} lg={1} md={1} sm={1} xs={1} className="newRowCard last">
        <Col>
          <h3>
            <i>User's review on this work: </i>
          </h3>
        </Col>

        {reviews &&
          reviews.map((res, i) => (
            <Col key={i}>
              <div className="reviews">
                <ReviewQuality 
                    revData = {res}
                    userInfo={userInfo}
                />
                <div>
                  <FaRegUserCircle size={50} /> <i> {res.user}</i>{" "}
                  <ReactStars
                    count={5}
                    size={30}
                    value={parseInt(res.assessment)}
                    edit={false}
                    activeColor="#ffd700"
                  />
                </div>
                <p>
                  <strong>Review: </strong>
                  {/* {res.review.substr(0, 50)} ...{" "} */}
                  {res.review}
                </p>
                <br />
                <strong>Recomend: </strong>
                {res.recomend}
                <br />
                <br />
              </div>
            </Col>
          ))}
      </Row>
    </Container>
  );
}

export default Works;