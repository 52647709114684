import React, { useState, useEffect } from "react";
import SearchBar from "./SearchBar";
import WorkList from "./WorkList";
import axios from "axios";
import { API } from "../../../config.json";
import { Row, Container } from "react-bootstrap";
import "./Search.css";

function SearchPage() {
  const [input, setInput] = useState("");
  const [workListDefault, setWorkListDefault] = useState();
  const [workList, setWorkList] = useState();

  const updateInput = (input) => {
    const filtered = workListDefault.filter((element) => {
      if( ! input ) return true;
      
      // if for some bad reason we do not have all params of an element (work) 
      if((element.keywords, element.title, element.abstract, element.user == null)) return false;

      return (
        element.keywords.toLowerCase().includes(input.toLowerCase()) || 
        element.title.toLowerCase().includes(input.toLowerCase()) ||
        element.abstract.toLowerCase().includes(input.toLowerCase()) || 
        element.user.toLowerCase().includes(input.toLowerCase())
      );
    });
    setInput(input);
    setWorkList(filtered);
  };
  useEffect(() => {
    const getAllWorks = () => {
      axios.get(`${API}routes/get_all_works.php`).then((res) => {
        setWorkList(res.data);
        setWorkListDefault(res.data);
      });
    };
    getAllWorks();
  }, []);
  return (
    <Container className="myCard" style={{ marginTop: "6rem" }}>
      <div className="searchBox">
        <SearchBar input={input} onChange={updateInput} />
      </div>
      <div style={{ maxWidth: "70%", margin: "0 auto" }}>
        <Row xl={2} lg={2} md={2} sm={2} xs={1} className="rowCard">
          <WorkList workList={workList} />
        </Row>
      </div>
    </Container>
  );
}

export default SearchPage;
